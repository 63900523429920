import _reactIsProductionMin from "./cjs/react-is.production.min.js";
export { _reactIsProductionMin as default };
export const AsyncMode = _reactIsProductionMin.AsyncMode,
      ConcurrentMode = _reactIsProductionMin.ConcurrentMode,
      ContextConsumer = _reactIsProductionMin.ContextConsumer,
      ContextProvider = _reactIsProductionMin.ContextProvider,
      Element = _reactIsProductionMin.Element,
      ForwardRef = _reactIsProductionMin.ForwardRef,
      Fragment = _reactIsProductionMin.Fragment,
      Lazy = _reactIsProductionMin.Lazy,
      Memo = _reactIsProductionMin.Memo,
      Portal = _reactIsProductionMin.Portal,
      Profiler = _reactIsProductionMin.Profiler,
      StrictMode = _reactIsProductionMin.StrictMode,
      Suspense = _reactIsProductionMin.Suspense,
      isAsyncMode = _reactIsProductionMin.isAsyncMode,
      isConcurrentMode = _reactIsProductionMin.isConcurrentMode,
      isContextConsumer = _reactIsProductionMin.isContextConsumer,
      isContextProvider = _reactIsProductionMin.isContextProvider,
      isElement = _reactIsProductionMin.isElement,
      isForwardRef = _reactIsProductionMin.isForwardRef,
      isFragment = _reactIsProductionMin.isFragment,
      isLazy = _reactIsProductionMin.isLazy,
      isMemo = _reactIsProductionMin.isMemo,
      isPortal = _reactIsProductionMin.isPortal,
      isProfiler = _reactIsProductionMin.isProfiler,
      isStrictMode = _reactIsProductionMin.isStrictMode,
      isSuspense = _reactIsProductionMin.isSuspense,
      isValidElementType = _reactIsProductionMin.isValidElementType,
      typeOf = _reactIsProductionMin.typeOf;